<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：新窗口页面
开始时间：2019-11-14
开发人员：吴荣江
最后修改：2019-11-14
备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <router-view id="newWindow"></router-view>
  </div>
</template>

<script>
export default {
  name: "newWindow",
};
</script>
<style src="@/css/new_window/newWindow.css"></style>
